.wrapper {
  margin: 12px;
  background: var(--c-white);
  border: 1px solid var(--c-gray10);

  .controller {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--c-gray10);

    .segment {
      margin-right: 24px;
      margin-left: auto;
    }
  }

  .chart {
    padding: 32px;
  }
}
