.form {
  padding: 24px 0 0;

  section {
    padding: 48px 32px 130px;

    h2 {
      font-size: 24px;
      font-weight: 400;
      color: var(--c-gray100);
      margin: 0 0 56px;
    }
  }

  footer {
    display: flex;
    height: 38px;
    padding: 16px 24px;
    justify-content: space-between;
    border-top: 1px solid var(--c-gray10);

    a {
      width: 128px;
      line-height: 0;
    }
  }
}
