.form {
  padding: 24px 0;

  section {
    padding: 0 32px;

    h2 {
      margin: 48px 0 52px;
      font-size: 24px;
      font-weight: 400;
      color: var(--c-gray100);
    }
  }

  .inputs {
    max-width: 468px;
    padding: 0 32px 111px;
  }

  footer {
    display: flex;
    height: 20px;
    padding: 16px 24px;
    justify-content: space-between;
    border-top: 1px solid var(--c-gray10);
  }
}
