.wrapper {
  padding: 24px 0 0;

  .section {
    padding: 0 32px;
    margin-bottom: 24px;

    .title {
      height: 58px;
      margin: 0;
      padding: 22px 0 0px;
      font-size: 24px;
      font-weight: 400;
      color: var(--c-gray100);
    }

    .description {
      line-height: 1.8em;
    }

    .warning {
      display: flex;
      height: 53px;
      background-color: var(--c-red-alpha);
      align-items: center;
      padding-left: 16px;
      margin-top: 21px;

      p {
        font-size: 14px;
        color: var(--c-red);
      }
    }
  }

  footer {
    display: flex;
    height: 38px;
    padding: 16px 24px;
    justify-content: space-between;
    border-top: 1px solid var(--c-gray10);
  }
}
