.wrapper {
  box-sizing: border-box;

  header {
    height: 36px;
    background: var(--c-white);
    padding: 10px 24px;
    border-bottom: 1px solid var(--c-gray10);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      margin: auto 8px;
      padding: 0;
    }
  }

  section {
    padding: 12px;

    .statistics {
      margin: 0 4px;
      display: flex;
    }
  }
}

.header_right {
  display: flex;

  & > div {
    margin-left: 16px;
  }

  .download {
    border: 1px solid var(--c-gray10);
    border-radius: 4px;

    a {
      padding: 8px 12px;
    }
  }

  .picker {
    width: 330px;

    & > div {
      border-radius: 4px;
      border: 1px solid var(--c-gray10);
    }
  }
}

.ranking {
  display: flex;
  flex-wrap: wrap;
}

.ranking_chart {
  height: 514px;
  width: 50%;
  margin-top: 24px;
}
