.form {
  section {
    padding: 24px 32px 38px;

    h2 {
      font-size: 24px;
      font-weight: 400;
      color: var(--c-gray100);
      margin: 116px 0 54px;
    }
  }

  footer {
    display: flex;
    height: 38px;
    padding: 16px 24px;
    justify-content: space-between;
    border-top: 1px solid var(--c-gray10);
  }
}
