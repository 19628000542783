.wrapper {
  margin: 12px;
  padding: 20px 0;
  background: var(--c-white);
  border: 1px solid var(--c-gray10);

  .header {
    padding: 0 24px 20px;
    margin: 0;
    border-bottom: 1px solid var(--c-gray10);

    p {
      font-size: 16px;
      color: var(--c-gray100);
      margin: 0;
    }
  }

  .titles {
    display: flex;
    margin: 24px 0 0 24px;

    .title {
      width: 50%;
      font-size: 14px;
      color: var(--c-gray100);
    }
  }

  .charts {
    display: flex;
    margin: 0 24px;

    .chart {
      width: 50%;
      margin-right: 124px;
    }
  }
}
