.wrapper {
  padding-bottom: 144px;
  background: var(--c-gray5);

  section {
    padding: 24px 32px;
    background: var(--c-white);
    border: 1px solid var(--c-gray10);
    border-top: none;

    h2 {
      font-size: 24px;
      font-weight: 400;
      color: var(--c-gray100);
      margin-top: 48px;
      margin-bottom: 24px;
    }

    p {
      font-size: 12px;
      color: var(--c-gray50);
      margin: 0;
    }

    .description {
      margin-bottom: 74px;
    }

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-bottom: 28px;

      .dropDown {
        width: 468px;
      }

      .box {
        display: flex;
        flex-direction: column;
        width: 384px;
        height: 56px;

        .subtitleButton {
          align-items: center;
          align-self: flex-end;
          border: 1px solid var(--c-gray20);
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 360px;
          height: 36px;

          .title {
            font-size: 14px;
            font-weight: 700;
          }

          img {
            margin-right: 10.25px;
            width: 13.5px;
            height: 13px;
          }
        }

        p {
          margin-top: 4px;
          font-size: 11px;
        }
      }
    }

    .fieldset {
      border: none;
      display: flex;
      flex-direction: row;
      gap: 22px;
      margin-bottom: 32px;
      padding: 0;

      input, input + label {
        cursor: pointer;
      };

      .checkbox {
        &::before {
          background: var(--c-white);
          border: 2px solid var(--c-gray10);
          border-radius: 4px;
          box-sizing: border-box;
          content: '';
          display: inline-block;
          height: 20px;
          left: -1px;
          position: relative;
          top: -1px;
          visibility: visible;
          width: 20px;
          }
        &:checked::after {
          background-image: url(/icon/check.svg);
          background-color: var(--c-brand);
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 4px;
          box-sizing: border-box;
          content: '';
          display: inline-block;
          height: 20px;
          left: -1px;
          position: relative;
          top: -22px;
          width: 20px;
          }
      }
      label {
        color: var(--c-gray80);
        font-size: 14px;
        font-weight: 500;
        margin-left: -12px;
      }
    }

    .warning {
      display: flex;
      height: 53px;
      background-color: var(--c-red-alpha);
      align-items: center;
      padding-left: 16px;
      margin-bottom: 24px;

      p {
        font-size: 14px;
        color: var(--c-red);
      }
    }

    .pickupToggle {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border: 1px solid var(--c-gray10);
      padding: 14px 16px;

      p {
        color: var(--c-gray100);
        font-size: 14px;
        font-weight: 400;
        margin-right: 4px;
      }
    }
  }

  footer {
    // 横幅 - サイドバー - 余白
    width: calc(100% - 208px - 24px * 4);
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border: 1px solid var(--c-gray10);
    border-radius: 6px;
    background: var(--c-white);
    box-shadow: 0px 0px 8px 0px #0B081A1F;
    z-index: 2;

    .initialFooter {
      display: flex;
      align-items: center;

      .warning {
        padding-right: 24px;

        p {
          font-size: 14px;
          color: var(--c-red);
        }
      }
    }
  }
}
