.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background: var(--c-gray50-alpha);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .modalContent {
    background: var(--c-white);
    border-radius: 12px;
    display: flex;
    height: 90vh;
    max-height: 800px;
    max-width: 976px;
    position: relative;
    width: 90vw;
  }

  .close {
    align-self: flex-start;
    align-items: center;
    background: var(--c-gray50);
    border-radius: 0px 4px 4px 0px;
    display: flex;
    flex-direction: column;
    min-height: 74px;
    justify-content: center;
    margin-top: 74px;
    min-width: 74px;

    &:hover {
      cursor: pointer;
    }

    span {
      font-size: 14px;
      color: var(--c-white);
      margin-top: 4px;
    }
  }

}
