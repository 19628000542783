.form {
  section {
    padding: 24px 32px;

    h2 {
      font-size: 24px;
      font-weight: 400;
      color: var(--c-gray100);
      margin-top: 48px;
      margin-bottom: 56px;
    }

    .upload {
      margin: 24px 0 74px;
      padding: 24px;
      border: 1px solid var(--c-gray10);

      .csv {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 16px;
        width: 372px;
        height: 56px;
        background: var(--c-gray5);
        border: 1px solid var(--c-gray10);
        box-sizing: border-box;
        margin-top: 24px;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-top: 1px solid var(--c-gray10);
  }
}

.latestPosFile {
  display: inline-block;
  margin-top: 16px;
  padding: 17px;
  background-color: var(--c-gray5);
  border: 1px solid var(--c-gray10);

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--c-gray100);
  }
}
